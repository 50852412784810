import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VImg } from 'vuetify/lib/components/VImg';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VContainer,
    { attrs: { "grid-list-xl": "" } },
    [
      _c(
        VForm,
        {
          ref: "form",
          staticClass: "settings",
          attrs: { "lazy-validation": "" },
          model: {
            value: _vm.valid,
            callback: function($$v) {
              _vm.valid = $$v
            },
            expression: "valid"
          }
        },
        [
          _c(
            VLayout,
            { attrs: { row: "", wrap: "" } },
            [
              _c(
                VLayout,
                [
                  _c(VFlex, [_c("view-title")], 1),
                  _c(
                    VFlex,
                    { attrs: { shrink: "", "fill-height": "" } },
                    [
                      _c(
                        VBtn,
                        {
                          attrs: {
                            color: "primary",
                            loading: _vm.savingLoading,
                            disabled: !_vm.isAdmin
                          },
                          on: {
                            click: function($event) {
                              _vm.dialog = true
                            }
                          }
                        },
                        [_vm._v("Delete Marketplace")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            VFlex,
            [
              _c(
                VLayout,
                { attrs: { row: "", wrap: "" } },
                [
                  _c(
                    VFlex,
                    {
                      staticStyle: { "margin-bottom": "100px" },
                      attrs: { xs12: "" }
                    },
                    [
                      _c(
                        VCard,
                        [
                          _c(
                            VCardTitle,
                            { attrs: { "primary-title": "" } },
                            [
                              _c("div", { staticClass: "H5-Primary-Left" }, [
                                _vm._v("Business Unit Information")
                              ])
                            ]
                          ),
                          _c(
                            VLayout,
                            [
                              _c(
                                VFlex,
                                { attrs: { xs12: "", md7: "" } },
                                [
                                  _c(
                                    VLayout,
                                    {
                                      staticClass: "ml-3",
                                      attrs: { wrap: "" }
                                    },
                                    [
                                      _c(
                                        VFlex,
                                        { attrs: { xs12: "" } },
                                        [
                                          _c(VTextField, {
                                            attrs: {
                                              label: "Business Unit Name",
                                              disabled: !_vm.isAdmin,
                                              rules: _vm.mandatoryFieldRules
                                            },
                                            model: {
                                              value: _vm.name,
                                              callback: function($$v) {
                                                _vm.name = $$v
                                              },
                                              expression: "name"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        VFlex,
                                        { attrs: { xs12: "" } },
                                        [
                                          _c(VTextField, {
                                            attrs: {
                                              label: "Business Unit Number",
                                              type: "number",
                                              disabled:
                                                !_vm.isNew &&
                                                !_vm.undefinedCostCenter,
                                              rules: _vm.mandatoryFieldRules
                                            },
                                            model: {
                                              value: _vm.costcenter,
                                              callback: function($$v) {
                                                _vm.costcenter = _vm._n($$v)
                                              },
                                              expression: "costcenter"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            VCardTitle,
                            { attrs: { "primary-title": "" } },
                            [
                              _c("div", { staticClass: "H5-Primary-Left" }, [
                                _vm._v("Marketplace Information")
                              ])
                            ]
                          ),
                          !_vm.isCanada
                            ? _c(
                                "div",
                                [
                                  _c(
                                    VLayout,
                                    [
                                      _c(
                                        VFlex,
                                        { attrs: { xs12: "", md7: "" } },
                                        [
                                          _c(
                                            VLayout,
                                            {
                                              staticClass: "ml-3",
                                              attrs: { wrap: "" }
                                            },
                                            [
                                              _c(
                                                VFlex,
                                                { attrs: { xs12: "" } },
                                                [
                                                  _c(VTextField, {
                                                    attrs: {
                                                      label: "Marketplace Name",
                                                      rules:
                                                        _vm.mandatoryFieldRules
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.market_place.label
                                                          .en,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.market_place
                                                            .label,
                                                          "en",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "market_place.label.en"
                                                    }
                                                  })
                                                ],
                                                1
                                              ),
                                              _c(
                                                VFlex,
                                                { attrs: { xs12: "" } },
                                                [
                                                  _c(VTextField, {
                                                    attrs: {
                                                      label: "Location/Address",
                                                      rules:
                                                        _vm.mandatoryFieldRules
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.market_place
                                                          .location_description
                                                          .en,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.market_place
                                                            .location_description,
                                                          "en",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "market_place.location_description.en"
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        VFlex,
                                        {
                                          staticStyle: {
                                            "padding-left": "35px"
                                          },
                                          attrs: { xs12: "", md5: "" }
                                        },
                                        [
                                          _c("image-uploader", {
                                            ref: "logoImage",
                                            attrs: {
                                              imageUrl: _vm.logoImageProxy,
                                              width: 324,
                                              height: 143
                                            },
                                            on: {
                                              "update:imageUrl": function(
                                                $event
                                              ) {
                                                _vm.logoImageProxy = $event
                                              },
                                              "update:image-url": function(
                                                $event
                                              ) {
                                                _vm.logoImageProxy = $event
                                              }
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.isCanada
                            ? _c(
                                "div",
                                [
                                  _c(
                                    VLayout,
                                    {
                                      staticClass: "ml-3",
                                      attrs: { wrap: "" }
                                    },
                                    [
                                      _c(VFlex, { attrs: { xs5: "" } }, [
                                        _c(
                                          "h5",
                                          {
                                            staticClass:
                                              "Subtitle-1-Black-Medium-Emphasis-Left font-weight-medium"
                                          },
                                          [
                                            _vm._v(
                                              "\n                    English Information\n                  "
                                            )
                                          ]
                                        )
                                      ])
                                    ],
                                    1
                                  ),
                                  _c(
                                    VLayout,
                                    [
                                      _c(
                                        VFlex,
                                        { attrs: { xs12: "", md7: "" } },
                                        [
                                          _c(
                                            VLayout,
                                            {
                                              staticClass: "ml-3",
                                              attrs: { wrap: "" }
                                            },
                                            [
                                              _c(
                                                VFlex,
                                                { attrs: { xs12: "" } },
                                                [
                                                  _c(VTextField, {
                                                    attrs: {
                                                      label: "Marketplace Name",
                                                      rules:
                                                        _vm.mandatoryFieldRules
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.market_place.label
                                                          .en,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.market_place
                                                            .label,
                                                          "en",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "market_place.label.en"
                                                    }
                                                  })
                                                ],
                                                1
                                              ),
                                              _c(
                                                VFlex,
                                                { attrs: { xs12: "" } },
                                                [
                                                  _c(VTextField, {
                                                    attrs: {
                                                      label: "Location/Address",
                                                      rules:
                                                        _vm.mandatoryFieldRules
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.market_place
                                                          .location_description
                                                          .en,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.market_place
                                                            .location_description,
                                                          "en",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "market_place.location_description.en"
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        VFlex,
                                        {
                                          staticStyle: {
                                            "padding-left": "35px"
                                          },
                                          attrs: { xs12: "", md5: "" }
                                        },
                                        [
                                          _c("image-uploader", {
                                            ref: "logoImage",
                                            attrs: {
                                              imageUrl: _vm.logoImageProxy,
                                              width: 324,
                                              height: 143
                                            },
                                            on: {
                                              "update:imageUrl": function(
                                                $event
                                              ) {
                                                _vm.logoImageProxy = $event
                                              },
                                              "update:image-url": function(
                                                $event
                                              ) {
                                                _vm.logoImageProxy = $event
                                              }
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    VLayout,
                                    {
                                      staticClass: "ml-3",
                                      attrs: { wrap: "" }
                                    },
                                    [
                                      _c(VFlex, { attrs: { xs5: "" } }, [
                                        _c(
                                          "h5",
                                          {
                                            staticClass:
                                              "Subtitle-1-Black-Medium-Emphasis-Left font-weight-medium"
                                          },
                                          [
                                            _vm._v(
                                              "\n                    French Information\n                  "
                                            )
                                          ]
                                        )
                                      ])
                                    ],
                                    1
                                  ),
                                  _c(
                                    VLayout,
                                    [
                                      _c(
                                        VFlex,
                                        { attrs: { xs12: "", md7: "" } },
                                        [
                                          _c(
                                            VLayout,
                                            {
                                              staticClass: "ml-3",
                                              attrs: { wrap: "" }
                                            },
                                            [
                                              _c(
                                                VFlex,
                                                { attrs: { xs12: "" } },
                                                [
                                                  _c(VTextField, {
                                                    attrs: {
                                                      label: "Marketplace Name"
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.market_place.label
                                                          .fr,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.market_place
                                                            .label,
                                                          "fr",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "market_place.label.fr"
                                                    }
                                                  })
                                                ],
                                                1
                                              ),
                                              _c(
                                                VFlex,
                                                { attrs: { xs12: "" } },
                                                [
                                                  _c(VTextField, {
                                                    attrs: {
                                                      label: "Location/Address"
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.market_place
                                                          .location_description
                                                          .fr,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.market_place
                                                            .location_description,
                                                          "fr",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "market_place.location_description.fr"
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            VCardTitle,
                            { attrs: { "primary-title": "" } },
                            [
                              _c("div", { staticClass: "H5-Primary-Left" }, [
                                _vm._v("Pickup Settings")
                              ])
                            ]
                          ),
                          _vm.market_place.is.pickup_supported
                            ? _c(
                                VLayout,
                                {
                                  staticClass: "ml-0",
                                  attrs: { column: "", wrap: "" }
                                },
                                [
                                  _c(
                                    VLayout,
                                    {
                                      staticClass: "ml-3",
                                      attrs: { wrap: "" }
                                    },
                                    [
                                      _c(VFlex, { attrs: { xs8: "" } }, [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "Caption-Black-Medium-Emphasis-Left"
                                          },
                                          [
                                            _vm._v(
                                              "\n                    Pickup is enabled (information inherited from stations)\n                  "
                                            )
                                          ]
                                        )
                                      ])
                                    ],
                                    1
                                  ),
                                  _c(
                                    VLayout,
                                    {
                                      staticClass: "ml-3",
                                      attrs: { wrap: "" }
                                    },
                                    [
                                      _c(VFlex, { attrs: { xs5: "" } }, [
                                        _c(
                                          "h5",
                                          {
                                            staticClass:
                                              "Subtitle-1-Black-High-Emphasis-Left"
                                          },
                                          [
                                            _vm._v(
                                              "\n                    Operating Hours\n                  "
                                            )
                                          ]
                                        )
                                      ])
                                    ],
                                    1
                                  ),
                                  _c(
                                    VLayout,
                                    [
                                      _c(
                                        VFlex,
                                        { attrs: { xs12: "", md7: "" } },
                                        [
                                          _c(
                                            VLayout,
                                            {
                                              staticClass: "ml-3",
                                              attrs: { wrap: "" }
                                            },
                                            [
                                              _c(
                                                VFlex,
                                                { attrs: { xs12: "" } },
                                                [
                                                  _c(VSelect, {
                                                    attrs: {
                                                      items: _vm.operatingHours,
                                                      "v-model": (_vm.market_place.is.pickup_supported = true),
                                                      value: true,
                                                      placeholder:
                                                        "Please select an operating hour type"
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    VLayout,
                                    {
                                      staticClass: "ml-3",
                                      attrs: { wrap: "" }
                                    },
                                    [
                                      _c(VFlex, { attrs: { xs5: "" } }, [
                                        _c(
                                          "h5",
                                          {
                                            staticClass:
                                              "Subtitle-1-Black-Medium-Emphasis-Left font-weight-medium"
                                          },
                                          [
                                            _vm._v(
                                              "\n                    Pickup Instructions\n                  "
                                            )
                                          ]
                                        )
                                      ])
                                    ],
                                    1
                                  ),
                                  !_vm.isCanada
                                    ? _c(
                                        "div",
                                        [
                                          _c(
                                            VLayout,
                                            [
                                              _c(
                                                VFlex,
                                                {
                                                  attrs: { xs12: "", md7: "" }
                                                },
                                                [
                                                  _c(
                                                    VLayout,
                                                    {
                                                      staticClass: "ml-3",
                                                      attrs: { wrap: "" }
                                                    },
                                                    [
                                                      _c(
                                                        VFlex,
                                                        { attrs: { xs12: "" } },
                                                        [
                                                          _c(VTextField, {
                                                            attrs: {
                                                              label:
                                                                "Pickup Instructions",
                                                              rules:
                                                                _vm.mandatoryFieldRules
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.market_place
                                                                  .pickup_instruction
                                                                  .en,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  _vm
                                                                    .market_place
                                                                    .pickup_instruction,
                                                                  "en",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "market_place.pickup_instruction.en"
                                                            }
                                                          })
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.isCanada
                                    ? _c(
                                        "div",
                                        [
                                          _c(
                                            VLayout,
                                            [
                                              _c(
                                                VFlex,
                                                {
                                                  attrs: { xs12: "", md7: "" }
                                                },
                                                [
                                                  _c(
                                                    VLayout,
                                                    {
                                                      staticClass: "ml-3",
                                                      attrs: { wrap: "" }
                                                    },
                                                    [
                                                      _c(
                                                        VFlex,
                                                        { attrs: { xs12: "" } },
                                                        [
                                                          _c(VTextField, {
                                                            attrs: {
                                                              label:
                                                                "English Pickup Instructions",
                                                              rules:
                                                                _vm.mandatoryFieldRules
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.market_place
                                                                  .pickup_instruction
                                                                  .en,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  _vm
                                                                    .market_place
                                                                    .pickup_instruction,
                                                                  "en",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "market_place.pickup_instruction.en"
                                                            }
                                                          })
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        VFlex,
                                                        { attrs: { xs12: "" } },
                                                        [
                                                          _c(VTextField, {
                                                            attrs: {
                                                              label:
                                                                "French Pickup Instructions"
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.market_place
                                                                  .pickup_instruction
                                                                  .fr,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  _vm
                                                                    .market_place
                                                                    .pickup_instruction,
                                                                  "fr",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "market_place.pickup_instruction.fr"
                                                            }
                                                          })
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            : _c(
                                VLayout,
                                {
                                  attrs: {
                                    "justify-center": "",
                                    "align-center": "",
                                    column: ""
                                  }
                                },
                                [
                                  _c(
                                    VFlex,
                                    { attrs: { xs4: "" } },
                                    [
                                      _c(VImg, {
                                        attrs: {
                                          src: require("@/views/sites/marketplace/empty-pickup.svg"),
                                          width: "150"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(VFlex, { attrs: { shrink: "" } }, [
                                    _c(
                                      "p",
                                      {
                                        staticClass:
                                          "Body-1-Black-High-Emphasis-Center"
                                      },
                                      [
                                        _vm._v(
                                          "\n                  Pickup is not currently configured at any of your stations.\n                "
                                        )
                                      ]
                                    ),
                                    _c(
                                      "p",
                                      {
                                        staticClass:
                                          "pickup_message Body-1-Black-Medium-Emphasis-Center"
                                      },
                                      [
                                        _vm._v(
                                          "\n                  Please visit the "
                                        ),
                                        _c("strong", [
                                          _vm._v("Station Configuration")
                                        ]),
                                        _vm._v(
                                          " page to setup Pickup\n                  Details before configuring them for your marketplace.\n                "
                                        )
                                      ]
                                    )
                                  ])
                                ],
                                1
                              ),
                          _c(
                            VCardTitle,
                            { attrs: { "primary-title": "" } },
                            [
                              _c("div", { staticClass: "H5-Primary-Left" }, [
                                _vm._v("Delivery Settings")
                              ])
                            ]
                          ),
                          !_vm.market_place.is.delivery_supported
                            ? _c("delivery-empty-state", {
                                attrs: {
                                  deliveryDestinations:
                                    _vm.siteDeliveryDestinations,
                                  locationDetails: _vm.locationDetails
                                }
                              })
                            : _c(
                                VLayout,
                                { staticClass: "ml-0", attrs: { column: "" } },
                                [
                                  _c(
                                    VLayout,
                                    {
                                      staticClass: "ml-3",
                                      attrs: { wrap: "" }
                                    },
                                    [
                                      _c(VFlex, { attrs: { xs10: "" } }, [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "Caption-Black-Medium-Emphasis-Left"
                                          },
                                          [
                                            _vm._v(
                                              "\n                    The below settings apply to all delivery-enabled, automatic timeslot stations.\n                    Timeslots and Drop-off Locations are inherited from Station Configurations.\n                  "
                                            )
                                          ]
                                        )
                                      ]),
                                      _c(
                                        VFlex,
                                        { attrs: { xs10: "" } },
                                        [
                                          _c("dropoff-locations", {
                                            attrs: {
                                              selectedLocations:
                                                _vm.market_place
                                                  .delivery_destinations,
                                              siteDeliveryDestinations:
                                                _vm.siteDeliveryDestinations,
                                              isTimeslotUserDefined: false,
                                              isBrandSectionDisabled: false
                                            },
                                            on: {
                                              updateSelectedLocations:
                                                _vm.handleUpdateDropoffLocations
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    VLayout,
                                    {
                                      staticClass: "ml-3",
                                      attrs: { wrap: "" }
                                    },
                                    [
                                      _c(VFlex, { attrs: { xs5: "" } }, [
                                        _c(
                                          "h5",
                                          {
                                            staticClass:
                                              "Subtitle-1-Black-High-Emphasis-Left"
                                          },
                                          [
                                            _vm._v(
                                              "\n                    Delivery Hours\n                  "
                                            )
                                          ]
                                        )
                                      ])
                                    ],
                                    1
                                  ),
                                  _c(
                                    VLayout,
                                    [
                                      _c(
                                        VFlex,
                                        { attrs: { xs12: "", md7: "" } },
                                        [
                                          _c(
                                            VLayout,
                                            {
                                              staticClass: "ml-3",
                                              attrs: { wrap: "" }
                                            },
                                            [
                                              _c(
                                                VFlex,
                                                { attrs: { xs12: "" } },
                                                [
                                                  _c(VSelect, {
                                                    attrs: {
                                                      items: _vm.operatingHours,
                                                      value: true,
                                                      placeholder:
                                                        "Please select an operating hour type"
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c("delivery-settings", {
                                    attrs: {
                                      show_instructions:
                                        _vm.market_place.delivery_details
                                          .show_instructions,
                                      show_single_timeslot:
                                        _vm.market_place.delivery_details
                                          .show_single_timeslot,
                                      runner_app_enabled:
                                        _vm.market_place.delivery_details
                                          .runner_app_enabled
                                    },
                                    on: {
                                      "update:show_instructions": function(
                                        $event
                                      ) {
                                        return _vm.$set(
                                          _vm.market_place.delivery_details,
                                          "show_instructions",
                                          $event
                                        )
                                      },
                                      "update:show_single_timeslot": function(
                                        $event
                                      ) {
                                        return _vm.$set(
                                          _vm.market_place.delivery_details,
                                          "show_single_timeslot",
                                          $event
                                        )
                                      },
                                      "update:runner_app_enabled": function(
                                        $event
                                      ) {
                                        return _vm.$set(
                                          _vm.market_place.delivery_details,
                                          "runner_app_enabled",
                                          $event
                                        )
                                      }
                                    }
                                  })
                                ],
                                1
                              ),
                          _c(
                            VCardTitle,
                            { attrs: { "primary-title": "" } },
                            [
                              _c("div", { staticClass: "H5-Primary-Left" }, [
                                _vm._v("Fee Setup")
                              ])
                            ]
                          ),
                          _c(
                            VLayout,
                            { staticClass: "ml-3", attrs: { wrap: "" } },
                            [
                              _c(VFlex, { attrs: { xs8: "" } }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "Caption-Black-Medium-Emphasis-Left"
                                  },
                                  [
                                    _vm._v(
                                      "\n                  Marketplace fees override fees set at stations within the marketplace\n                "
                                    )
                                  ]
                                )
                              ])
                            ],
                            1
                          ),
                          _c(
                            VLayout,
                            { staticClass: "ml-3", attrs: { wrap: "" } },
                            [
                              _c(
                                VFlex,
                                { attrs: { xs12: "" } },
                                [
                                  _c(
                                    VLayout,
                                    [
                                      _c(
                                        VFlex,
                                        {
                                          staticClass: "pa-0",
                                          attrs: { "offset-xs3": "", xs3: "" }
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "Subtitle-1-Black-Medium-Emphasis-Left"
                                            },
                                            [_vm._v("Enable Fee")]
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    VLayout,
                                    { attrs: { wrap: "", "align-center": "" } },
                                    [
                                      _c(VFlex, { attrs: { xs3: "" } }, [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "Subtitle-1-Black-High-Emphasis-Left"
                                          },
                                          [_vm._v("Service Fee")]
                                        )
                                      ]),
                                      _c(
                                        VFlex,
                                        { attrs: { xs3: "" } },
                                        [
                                          _c(VCheckbox, {
                                            model: {
                                              value: _vm.feeEnabled,
                                              callback: function($$v) {
                                                _vm.feeEnabled = $$v
                                              },
                                              expression: "feeEnabled"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm.feeEnabled
                                        ? _c(
                                            VFlex,
                                            { attrs: { xs3: "" } },
                                            [
                                              _c(VTextField, {
                                                attrs: {
                                                  label: _vm.label(
                                                    "serviceFeeProxy"
                                                  ),
                                                  type: "number",
                                                  prefix: _vm.prefix(
                                                    "serviceFeeProxy"
                                                  ),
                                                  rules: _vm.fieldRules,
                                                  value:
                                                    _vm.serviceFeeProxy.value
                                                },
                                                on: {
                                                  input: function($event) {
                                                    return _vm.onFeeChange(
                                                      "serviceFeeProxy",
                                                      $event
                                                    )
                                                  }
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  ),
                                  _c(
                                    VLayout,
                                    [
                                      _c(
                                        VFlex,
                                        {
                                          staticClass: "pa-0",
                                          attrs: { "offset-xs3": "", xs3: "" }
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "Subtitle-1-Black-Medium-Emphasis-Left"
                                            },
                                            [_vm._v("Enable Fee")]
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    VLayout,
                                    { attrs: { wrap: "", "align-center": "" } },
                                    [
                                      _c(VFlex, { attrs: { xs3: "" } }, [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "Subtitle-1-Black-High-Emphasis-Left"
                                          },
                                          [_vm._v("Delivery Fee")]
                                        )
                                      ]),
                                      _c(
                                        VFlex,
                                        { attrs: { xs3: "" } },
                                        [
                                          _c(VCheckbox, {
                                            model: {
                                              value: _vm.deliveryFeeEnabled,
                                              callback: function($$v) {
                                                _vm.deliveryFeeEnabled = $$v
                                              },
                                              expression: "deliveryFeeEnabled"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm.deliveryFeeEnabled
                                        ? _c(
                                            VFlex,
                                            { attrs: { xs3: "" } },
                                            [
                                              _c(VTextField, {
                                                attrs: {
                                                  label: _vm.label(
                                                    "deliveryFeeProxy"
                                                  ),
                                                  type: "number",
                                                  prefix: _vm.prefix(
                                                    "deliveryFeeProxy"
                                                  ),
                                                  rules: _vm.fieldRules,
                                                  value:
                                                    _vm.deliveryFeeProxy.value
                                                },
                                                on: {
                                                  input: function($event) {
                                                    return _vm.onFeeChange(
                                                      "deliveryFeeProxy",
                                                      $event
                                                    )
                                                  }
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(VSpacer)
            ],
            1
          ),
          _c("marketplace-modal", {
            attrs: { dialog: _vm.MarketplaceModal.dialog },
            on: {
              "update:dialog": function($event) {
                return _vm.$set(_vm.MarketplaceModal, "dialog", $event)
              }
            }
          }),
          _vm.isModified || _vm.isKDSModified
            ? _c("save-footer", {
                attrs: {
                  cancelAction: _vm.cancel,
                  saveLabel: "Save Business Unit",
                  saveAction: _vm.save
                }
              })
            : _vm._e(),
          _c(
            VDialog,
            {
              attrs: { width: "560" },
              on: {
                keydown: function($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "esc", 27, $event.key, [
                      "Esc",
                      "Escape"
                    ])
                  ) {
                    return null
                  }
                  _vm.dialog = false
                }
              },
              model: {
                value: _vm.dialog,
                callback: function($$v) {
                  _vm.dialog = $$v
                },
                expression: "dialog"
              }
            },
            [
              _c("delete-marketplace", {
                attrs: { location: _vm.locationDetails, serviceType: "DELETE" },
                on: {
                  dismissDialog: _vm.closeDialog,
                  handleMarketplaceStatusChange:
                    _vm.handleMarketplaceStatusChange,
                  "update:location": function($event) {
                    _vm.locationDetails = $event
                  }
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }