import { VBtn } from 'vuetify/lib/components/VBtn';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';
import { VLayout } from 'vuetify/lib/components/VGrid';

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VLayout,
    { attrs: { "justify-center": "", "align-center": "", column: "" } },
    [
      _c(
        VFlex,
        { attrs: { xs4: "" } },
        [
          _c(VImg, {
            attrs: {
              src: require("@/views/sites/marketplace/delivery-placeholder.svg"),
              width: "215"
            }
          })
        ],
        1
      ),
      _vm.deliveryDestinations.length === 0
        ? _c(
            VFlex,
            { attrs: { shrink: "" } },
            [
              _c("p", { staticClass: "Body-1-Black-High-Emphasis-Center" }, [
                _vm._v("\n      There are no drop-off locations set up\n    ")
              ]),
              _c(
                VLayout,
                { attrs: { "align-center": "", column: "" } },
                [
                  _c(
                    "p",
                    { staticClass: "Body-1-Black-Medium-Emphasis-Center" },
                    [
                      _vm._v("\n        Please go to "),
                      _c("strong", [_vm._v("Site Configuration")]),
                      _vm._v(" to add drop-off locations first\n      ")
                    ]
                  ),
                  _c(
                    VBtn,
                    {
                      attrs: { color: "primary" },
                      on: { click: _vm.goToSiteSettings }
                    },
                    [_vm._v("\n        SITE CONFIGURATION\n      ")]
                  )
                ],
                1
              )
            ],
            1
          )
        : _c(
            VFlex,
            { attrs: { shrink: "" } },
            [
              _c("p", { staticClass: "Body-1-Black-High-Emphasis-Center" }, [
                _vm._v(
                  "\n      Delivery is not available for this Marketplace\n    "
                )
              ]),
              _c(VLayout, { attrs: { "align-center": "", column: "" } }, [
                _c("p", { staticClass: "Body-1-Black-High-Emphasis-Center" }, [
                  _c("strong", [
                    _vm._v(
                      "There needs to be at least two stations with automatic timeslots for delivery\n          set-up."
                    )
                  ])
                ]),
                _c(
                  "p",
                  { staticClass: "Body-1-Black-Medium-Emphasis-Center" },
                  [
                    _vm._v("\n        Please visit the "),
                    _c("strong", [_vm._v("Station Configuration")]),
                    _vm._v(" page to setup Delivery Details\n      ")
                  ]
                )
              ])
            ],
            1
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }