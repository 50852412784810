import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VDialog,
    {
      attrs: { persistent: "", width: "800px", height: "600px" },
      model: {
        value: _vm.dialogSync,
        callback: function($$v) {
          _vm.dialogSync = $$v
        },
        expression: "dialogSync"
      }
    },
    [
      _c(
        VCard,
        { staticClass: "px-2 py-2" },
        [
          _c(VCardTitle, { staticClass: "H4-Secondary-Center" }, [
            _vm._v("Enabling Expeditor for Marketplacet")
          ]),
          _c(
            VLayout,
            { staticClass: "ml-3", attrs: { wrap: "" } },
            [
              _c(VFlex, { attrs: { xs12: "" } }, [
                _c("div", { staticClass: "Body-1-Black-High-Emphasis-Left" }, [
                  _vm._v(
                    "\n          Enabling this setting requires a KDS set up in Expeditor Mode to complete an order\n          before notifying the customer/runner\n        "
                  )
                ]),
                _c("br"),
                _c("div", { staticClass: "Body-1-Black-High-Emphasis-Left" }, [
                  _vm._v(
                    "\n          When disabled, the customer/runner will be notified automatically once all station chits\n          are Finished\n        "
                  )
                ]),
                _c("br")
              ])
            ],
            1
          ),
          _c(
            VCardActions,
            [
              _c(VSpacer),
              _c(
                VBtn,
                {
                  attrs: { flat: "", color: "blue" },
                  on: { click: _vm.close }
                },
                [_vm._v("GOT IT")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }