<template>
  <v-layout justify-center align-center column>
    <v-flex xs4>
      <v-img
        :src="require('@/views/sites/marketplace/delivery-placeholder.svg')"
        width="215"
      ></v-img>
    </v-flex>
    <v-flex shrink v-if="deliveryDestinations.length === 0">
      <p class="Body-1-Black-High-Emphasis-Center">
        There are no drop-off locations set up
      </p>
      <v-layout align-center column>
        <p class="Body-1-Black-Medium-Emphasis-Center">
          Please go to <strong>Site Configuration</strong> to add drop-off locations first
        </p>
        <v-btn color="primary" @click="goToSiteSettings">
          SITE CONFIGURATION
        </v-btn>
      </v-layout>
    </v-flex>
    <v-flex shrink v-else>
      <p class="Body-1-Black-High-Emphasis-Center">
        Delivery is not available for this Marketplace
      </p>
      <v-layout align-center column>
        <p class="Body-1-Black-High-Emphasis-Center">
          <strong
            >There needs to be at least two stations with automatic timeslots for delivery
            set-up.</strong
          >
        </p>

        <p class="Body-1-Black-Medium-Emphasis-Center">
          Please visit the <strong>Station Configuration</strong> page to setup Delivery Details
        </p>
      </v-layout>
    </v-flex>
  </v-layout>
</template>

<script>
export default {
  props: {
    deliveryDestinations: {
      type: Array,
    },
    locationDetails: {
      type: Object,
    },
  },
  methods: {
    goToSiteSettings() {
      this.$router.push({
        name: 'site-settings',
        params: {
          site_id: this.locationDetails.location_group,
        },
      });
    },
  },
};
</script>
