<template>
  <v-layout column ml-3>
    <v-flex xs8>
      <p class="Subtitle-1-Black-Medium-Emphasis-Left font-weight-medium">
        Display timeslots as one time?
      </p>
      <div class="toggle_margin">
        <toggle
          class="toggle"
          :value="show_single_timeslot"
          @input="$emit('update:show_single_timeslot', $event)"
          position="start"
        />
      </div>
    </v-flex>

    <v-flex xs8>
      <p class="Subtitle-1-Black-Medium-Emphasis-Left font-weight-medium">
        Allow delivery instruction?
      </p>
      <div class="toggle_margin">
        <toggle
          class="toggle"
          :value="show_instructions"
          @input="$emit('update:show_instructions', $event)"
          position="start"
        />
      </div>
    </v-flex>

    <v-flex xs8>
      <p class="Subtitle-1-Black-Medium-Emphasis-Left font-weight-medium">
        Enable Bolter delivery app?
      </p>
      <div class="toggle_margin">
        <toggle
          class="toggle"
          :value="runner_app_enabled"
          @input="$emit('update:runner_app_enabled', $event)"
          position="start"
        />
      </div>
    </v-flex>
  </v-layout>
</template>

<script>
export default {
  props: {
    show_single_timeslot: {
      type: Boolean,
      default: false,
    },
    show_instructions: {
      type: Boolean,
      default: false,
    },
    runner_app_enabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
