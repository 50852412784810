<template>
  <v-dialog persistent width="800px" height="600px" v-model="dialogSync">
    <v-card class="px-2 py-2">
      <v-card-title class="H4-Secondary-Center">Enabling Expeditor for Marketplacet</v-card-title>
      <v-layout wrap class="ml-3">
        <v-flex xs12>
          <div class="Body-1-Black-High-Emphasis-Left">
            Enabling this setting requires a KDS set up in Expeditor Mode to complete an order
            before notifying the customer/runner
          </div>
          <br />
          <div class="Body-1-Black-High-Emphasis-Left">
            When disabled, the customer/runner will be notified automatically once all station chits
            are Finished
          </div>
          <br />
        </v-flex>
      </v-layout>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn flat color="blue" @click="close">GOT IT</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  props: {
    dialog: {
      default: false,
    },
    site: Object,
  },

  data: () => ({}),

  computed: {
    dialogSync: {
      get() {
        return this.dialog;
      },
      set(v) {
        this.$emit('update:dialog', v);
      },
    },
  },
  methods: {
    close() {
      this.dialogSync = false;
    },
  },
};
</script>
