<template>
  <v-container grid-list-xl>
    <v-form ref="form" lazy-validation class="settings" v-model="valid">
      <v-layout row wrap>
        <v-layout>
          <v-flex>
            <view-title />
          </v-flex>

          <v-flex shrink fill-height>
            <v-btn
              color="primary"
              @click="dialog = true"
              :loading="savingLoading"
              :disabled="!isAdmin"
              >Delete Marketplace</v-btn
            >
          </v-flex>
        </v-layout>
      </v-layout>
      <v-flex>
        <v-layout row wrap>
          <v-flex xs12 style="margin-bottom: 100px;">
            <v-card>
              <v-card-title primary-title>
                <div class="H5-Primary-Left">Business Unit Information</div>
              </v-card-title>
              <v-layout>
                <v-flex xs12 md7>
                  <v-layout wrap class="ml-3">
                    <v-flex xs12>
                      <v-text-field
                        label="Business Unit Name"
                        v-model="name"
                        :disabled="!isAdmin"
                        :rules="mandatoryFieldRules"
                      />
                    </v-flex>
                    <v-flex xs12>
                      <v-text-field
                        label="Business Unit Number"
                        v-model.number="costcenter"
                        type="number"
                        :disabled="!isNew && !undefinedCostCenter"
                        :rules="mandatoryFieldRules"
                      />
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>

              <v-card-title primary-title>
                <div class="H5-Primary-Left">Marketplace Information</div>
              </v-card-title>
              <div v-if="!isCanada">
                <v-layout>
                  <v-flex xs12 md7>
                    <v-layout wrap class="ml-3">
                      <v-flex xs12>
                        <v-text-field
                          label="Marketplace Name"
                          v-model="market_place.label.en"
                          :rules="mandatoryFieldRules"
                        />
                      </v-flex>
                      <v-flex xs12>
                        <v-text-field
                          label="Location/Address"
                          v-model="market_place.location_description.en"
                          :rules="mandatoryFieldRules"
                        />
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs12 md5 style="padding-left: 35px;">
                    <image-uploader
                      :imageUrl.sync="logoImageProxy"
                      ref="logoImage"
                      :width="324"
                      :height="143"
                    />
                  </v-flex>
                </v-layout>
              </div>
              <div v-if="isCanada">
                <v-layout wrap class="ml-3">
                  <v-flex xs5>
                    <h5 class="Subtitle-1-Black-Medium-Emphasis-Left font-weight-medium">
                      English Information
                    </h5>
                  </v-flex>
                </v-layout>
                <v-layout>
                  <v-flex xs12 md7>
                    <v-layout wrap class="ml-3">
                      <v-flex xs12>
                        <v-text-field
                          label="Marketplace Name"
                          v-model="market_place.label.en"
                          :rules="mandatoryFieldRules"
                        />
                      </v-flex>
                      <v-flex xs12>
                        <v-text-field
                          label="Location/Address"
                          v-model="market_place.location_description.en"
                          :rules="mandatoryFieldRules"
                        />
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs12 md5 style="padding-left: 35px;">
                    <image-uploader
                      :imageUrl.sync="logoImageProxy"
                      ref="logoImage"
                      :width="324"
                      :height="143"
                    />
                  </v-flex>
                </v-layout>
                <v-layout wrap class="ml-3">
                  <v-flex xs5>
                    <h5 class="Subtitle-1-Black-Medium-Emphasis-Left font-weight-medium">
                      French Information
                    </h5>
                  </v-flex>
                </v-layout>
                <v-layout>
                  <v-flex xs12 md7>
                    <v-layout wrap class="ml-3">
                      <v-flex xs12>
                        <v-text-field label="Marketplace Name" v-model="market_place.label.fr" />
                      </v-flex>

                      <v-flex xs12>
                        <v-text-field
                          label="Location/Address"
                          v-model="market_place.location_description.fr"
                        />
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </div>
              <v-card-title primary-title>
                <div class="H5-Primary-Left">Pickup Settings</div>
              </v-card-title>
              <v-layout v-if="market_place.is.pickup_supported" column wrap class="ml-0">
                <v-layout wrap class="ml-3">
                  <v-flex xs8>
                    <div class="Caption-Black-Medium-Emphasis-Left">
                      Pickup is enabled (information inherited from stations)
                    </div>
                  </v-flex>
                </v-layout>
                <v-layout wrap class="ml-3">
                  <v-flex xs5>
                    <h5 class="Subtitle-1-Black-High-Emphasis-Left">
                      Operating Hours
                    </h5>
                  </v-flex>
                </v-layout>
                <v-layout>
                  <v-flex xs12 md7>
                    <v-layout wrap class="ml-3">
                      <v-flex xs12>
                        <v-select
                          :items="operatingHours"
                          :v-model="(market_place.is.pickup_supported = true)"
                          :value="true"
                          placeholder="Please select an operating hour type"
                        />
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
                <v-layout wrap class="ml-3">
                  <v-flex xs5>
                    <h5 class="Subtitle-1-Black-Medium-Emphasis-Left font-weight-medium">
                      Pickup Instructions
                    </h5>
                  </v-flex>
                </v-layout>
                <div v-if="!isCanada">
                  <v-layout>
                    <v-flex xs12 md7>
                      <v-layout wrap class="ml-3">
                        <v-flex xs12>
                          <v-text-field
                            label="Pickup Instructions"
                            v-model="market_place.pickup_instruction.en"
                            :rules="mandatoryFieldRules"
                          />
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </div>
                <div v-if="isCanada">
                  <v-layout>
                    <v-flex xs12 md7>
                      <v-layout wrap class="ml-3">
                        <v-flex xs12>
                          <v-text-field
                            label="English Pickup Instructions"
                            v-model="market_place.pickup_instruction.en"
                            :rules="mandatoryFieldRules"
                          />
                        </v-flex>
                        <v-flex xs12>
                          <v-text-field
                            label="French Pickup Instructions"
                            v-model="market_place.pickup_instruction.fr"
                          />
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </div>
              </v-layout>
              <v-layout v-else justify-center align-center column>
                <v-flex xs4>
                  <v-img
                    :src="require('@/views/sites/marketplace/empty-pickup.svg')"
                    width="150"
                  ></v-img>
                </v-flex>
                <v-flex shrink>
                  <p class="Body-1-Black-High-Emphasis-Center">
                    Pickup is not currently configured at any of your stations.
                  </p>
                  <p class="pickup_message Body-1-Black-Medium-Emphasis-Center">
                    Please visit the <strong>Station Configuration</strong> page to setup Pickup
                    Details before configuring them for your marketplace.
                  </p>
                </v-flex>
              </v-layout>
              <v-card-title primary-title>
                <div class="H5-Primary-Left">Delivery Settings</div>
              </v-card-title>

              <delivery-empty-state
                v-if="!market_place.is.delivery_supported"
                :deliveryDestinations="siteDeliveryDestinations"
                :locationDetails="locationDetails"
              />
              <v-layout v-else column class="ml-0">
                <v-layout wrap class="ml-3">
                  <v-flex xs10>
                    <div class="Caption-Black-Medium-Emphasis-Left">
                      The below settings apply to all delivery-enabled, automatic timeslot stations.
                      Timeslots and Drop-off Locations are inherited from Station Configurations.
                    </div>
                  </v-flex>
                  <v-flex xs10>
                    <dropoff-locations
                      :selectedLocations="market_place.delivery_destinations"
                      :siteDeliveryDestinations="siteDeliveryDestinations"
                      :isTimeslotUserDefined="false"
                      @updateSelectedLocations="handleUpdateDropoffLocations"
                      :isBrandSectionDisabled="false"
                    />
                  </v-flex>
                </v-layout>
                <v-layout wrap class="ml-3">
                  <v-flex xs5>
                    <h5 class="Subtitle-1-Black-High-Emphasis-Left">
                      Delivery Hours
                    </h5>
                  </v-flex>
                </v-layout>
                <v-layout>
                  <v-flex xs12 md7>
                    <v-layout wrap class="ml-3">
                      <v-flex xs12>
                        <v-select
                          :items="operatingHours"
                          :value="true"
                          placeholder="Please select an operating hour type"
                        />
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
                <delivery-settings
                  :show_instructions.sync="market_place.delivery_details.show_instructions"
                  :show_single_timeslot.sync="market_place.delivery_details.show_single_timeslot"
                  :runner_app_enabled.sync="market_place.delivery_details.runner_app_enabled"
                />
              </v-layout>

              <v-card-title primary-title>
                <div class="H5-Primary-Left">Fee Setup</div>
              </v-card-title>
              <v-layout wrap class="ml-3">
                <v-flex xs8>
                  <div class="Caption-Black-Medium-Emphasis-Left">
                    Marketplace fees override fees set at stations within the marketplace
                  </div>
                </v-flex>
              </v-layout>
              <v-layout wrap class="ml-3">
                <v-flex xs12>
                  <v-layout>
                    <v-flex offset-xs3 xs3 class="pa-0">
                      <span class="Subtitle-1-Black-Medium-Emphasis-Left">Enable Fee</span>
                    </v-flex>
                  </v-layout>
                  <v-layout wrap align-center>
                    <v-flex xs3>
                      <span class="Subtitle-1-Black-High-Emphasis-Left">Service Fee</span>
                    </v-flex>
                    <v-flex xs3>
                      <v-checkbox v-model="feeEnabled" />
                    </v-flex>
                    <v-flex xs3 v-if="feeEnabled">
                      <v-text-field
                        :label="label('serviceFeeProxy')"
                        type="number"
                        :prefix="prefix('serviceFeeProxy')"
                        :rules="fieldRules"
                        :value="serviceFeeProxy.value"
                        @input="onFeeChange('serviceFeeProxy', $event)"
                      />
                    </v-flex>
                  </v-layout>
                  <v-layout>
                    <v-flex offset-xs3 xs3 class="pa-0">
                      <span class="Subtitle-1-Black-Medium-Emphasis-Left">Enable Fee</span>
                    </v-flex>
                  </v-layout>
                  <v-layout wrap align-center>
                    <v-flex xs3>
                      <span class="Subtitle-1-Black-High-Emphasis-Left">Delivery Fee</span>
                    </v-flex>
                    <v-flex xs3>
                      <v-checkbox v-model="deliveryFeeEnabled" />
                    </v-flex>
                    <v-flex xs3 v-if="deliveryFeeEnabled">
                      <v-text-field
                        :label="label('deliveryFeeProxy')"
                        type="number"
                        :prefix="prefix('deliveryFeeProxy')"
                        :rules="fieldRules"
                        :value="deliveryFeeProxy.value"
                        @input="onFeeChange('deliveryFeeProxy', $event)"
                      />
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-card>
          </v-flex>
        </v-layout>
        <v-spacer />
      </v-flex>
      <marketplace-modal :dialog.sync="MarketplaceModal.dialog" />
      <save-footer
        v-if="isModified || isKDSModified"
        :cancelAction="cancel"
        saveLabel="Save Business Unit"
        :saveAction="save"
      />
      <v-dialog v-model="dialog" width="560" @keydown.esc="dialog = false">
        <delete-marketplace
          @dismissDialog="closeDialog"
          @handleMarketplaceStatusChange="handleMarketplaceStatusChange"
          :location.sync="locationDetails"
          serviceType="DELETE"
        ></delete-marketplace>
      </v-dialog>
    </v-form>
  </v-container>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep';
import { mapActions, mapState, mapGetters } from 'vuex';
import isEqual from 'lodash/isEqual';
import rules from '@/rules';
import imageUploader from '@/components/imageUploader';
import deliverySettings from '@/components/Marketplace/deliverySettings.vue';
import dropoffLocations from '@/components/BrandSettings/DropoffLocations';
import deleteMarketplace from './marketplaceStatusDialog.vue';
import MarketplaceModal from './marketplaceModal.vue';
import deliveryEmptyState from './deliveryEmptyState.vue';

export default {
  components: {
    imageUploader,
    MarketplaceModal,
    'delete-marketplace': deleteMarketplace,
    'delivery-settings': deliverySettings,
    'delivery-empty-state': deliveryEmptyState,
    'dropoff-locations': dropoffLocations,
  },
  async beforeRouteLeave(to, from, next) {
    if (this.isModified || this.isKDSModified) {
      const input = await this.$confirm({
        title: 'Leave without saving?',
        message: 'Changes will be lost if you do not save.',
        buttonTrueText: 'LEAVE',
        buttonFalseText: 'cancel',
      });
      if (input) {
        // localStorage cleanup
        if (localStorage.location) {
          localStorage.removeItem('location');
        }
        next();
      } else {
        next(false);
      }
    } else {
      // localStorage cleanup
      if (localStorage.location) {
        localStorage.removeItem('location');
      }
      next();
    }
  },
  data: () => ({
    location_id: '',
    loading: false,
    dialog: false,
    savingLoading: false,
    deleteMarketplaceConfirmed: false,
    name: '',
    costcenter: '',
    undefinedCostCenter: false,
    mandatoryFieldRules: [rules.required('Mandatory Field')],
    valid: true,
    siteDetails: {},
    locationDetails: {},
    serviceFeeHadValue: false,
    privateConfig: {},
    locationConfig: {},
    childBrandsWithPublicConfig: [],
    backup: {
      name: '',
      costcenter: '',
      market_place: {
        label: {
          en: '',
          fr: '',
        },
        location_description: {
          en: '',
          fr: '',
        },
        logo: '',
        is: {
          pickup_supported: true,
          delivery_supported: false,
        },
        delivery_details: {
          show_single_timeslot: false,
          show_instructions: false,
          runner_app_enabled: false,
        },
        pickup_instruction: { en: '', fr: '' },
        service_fee: {
          type: 'dollar',
          value: 0,
        },
        delivery_fee: {
          type: 'dollar',
          value: 0,
        },
        delivery_destinations: [],
      },
      kds_configuration: {
        business_unit_id: '',
        has_expediter_kds: false,
      },
    },
    operatingHours: [
      {
        text: 'Auto (Set from station opening first and closing last)',
        value: true,
      },
    ],
    fee_base: {
      type: 'dollar',
      value: 0,
    },
    market_place: {
      label: {
        en: '',
        fr: '',
      },
      location_description: {
        en: '',
        fr: '',
      },
      logo: '',
      is: {
        pickup_supported: true,
        delivery_supported: false,
      },
      delivery_details: {
        show_single_timeslot: false,
        show_instructions: false,
        runner_app_enabled: false,
      },
      pickup_instruction: { en: '', fr: '' },
      service_fee: {
        type: 'dollar',
        value: 0,
      },
      delivery_fee: {
        type: 'dollar',
        value: 0,
      },
      delivery_destinations: [],
    },
    kds_configuration: {
      business_unit_id: '',
      has_expediter_kds: false,
    },
    MarketplaceModal: {
      dialog: false,
    },
    siteDeliveryDestinations: [],
  }),

  watch: {},
  computed: {
    ...mapState('sites', ['siteMap', 'active_site']),
    ...mapGetters('adminPanel', ['isAdmin']),
    feeEnabled: {
      get() {
        return this.market_place.service_fee;
      },
      set(v) {
        if (v) {
          this.$set(this.market_place, 'service_fee', {
            type: 'dollar',
            value: '',
          });
        } else {
          this.market_place.service_fee.value = 0;
          this.$set(this.market_place, 'service_fee', {});
          delete this.market_place.service_fee;
          const isServiceFeeEmpty =
            this.market_place && this.market_place.service_fee === undefined;
          // check if any other fields are modified before updating backup
          if (isServiceFeeEmpty && !this.serviceFeeHadValue && !this.isModified) {
            this.$set(this.backup, 'market_place', cloneDeep(this.market_place));
          }
        }
      },
    },
    deliveryFeeEnabled: {
      get() {
        return this.market_place.delivery_fee;
      },
      set(v) {
        if (v) {
          this.$set(this.market_place, 'delivery_fee', this.fee_base);
        } else {
          const { delivery_fee, ...new_market_place } = this.market_place;
          this.$set(this, 'market_place', new_market_place);
        }
      },
    },
    serviceFeeProxy: {
      get() {
        return this.market_place.service_fee || this.fee_base;
      },
      set(v) {
        this.$set(this.market_place, 'service_fee', v);
      },
    },
    deliveryFeeProxy: {
      get() {
        return this.market_place.delivery_fee || this.fee_base;
      },
      set(v) {
        this.$set(this.market_place, 'delivery_fee', v);
      },
    },
    logoImageProxy: {
      get() {
        return (
          (this.locationDetails &&
            this.locationDetails.market_place &&
            this.locationDetails.market_place.logo) ||
          ''
        );
      },
      set(v) {
        this.$set(this.market_place, 'logo', v);
        this.$emit('input', {
          ...this.market_place,
          logo: v,
        });
      },
    },
    isNew() {
      return !(this.locationDetails && this.locationDetails.id);
    },
    isModified() {
      const name = isEqual(this.backup.name, this.name);
      const number = isEqual(this.backup.costcenter, this.costcenter);
      // have to check all nested objects manually else it will treat market_place as equal
      const label = isEqual(this.backup.market_place.label, this.market_place.label);
      const description = isEqual(
        this.backup.market_place.location_description,
        this.market_place.location_description,
      );
      const pickup = isEqual(
        this.backup.market_place.pickup_instruction,
        this.market_place.pickup_instruction,
      );
      const serviceFee = isEqual(
        this.backup.market_place.service_fee,
        this.market_place.service_fee,
      );
      const deliveryFee = isEqual(
        this.backup.market_place.delivery_fee,
        this.market_place.delivery_fee,
      );
      const deliveryDetails = isEqual(
        this.backup.market_place.delivery_details,
        this.market_place.delivery_details,
      );
      const deliveryDestinations = isEqual(
        this.backup.market_place.delivery_destinations,
        this.market_place.delivery_destinations,
      );
      const logo = isEqual(this.backup.market_place.logo, this.market_place.logo);

      return !(
        name &&
        number &&
        label &&
        description &&
        pickup &&
        serviceFee &&
        logo &&
        deliveryDetails &&
        deliveryFee &&
        deliveryDestinations
      );
    },
    isKDSModified() {
      return !(
        isEqual(
          this.kds_configuration.has_expediter_kds,
          this.backup.kds_configuration.has_expediter_kds,
        ) &&
        isEqual(
          this.kds_configuration.business_unit_id,
          this.backup.kds_configuration.business_unit_id,
        )
      );
    },
    isCanada() {
      if (this.siteDetails && this.siteDetails.address && this.siteDetails.address.country) {
        return this.siteDetails.address.country === 'CA';
      }
      return false;
    },
    fieldRules() {
      if (this.serviceFeeProxy.value !== null) {
        return [
          rules.required('Flat service fee is required'),
          rules.min(0.01, 'Service fee must be higher than 0'),
        ];
      }
      return [];
    },
    isDeliverySupported() {
      return (
        this.locationDetails?.brands &&
        this.locationDetails.brands.filter((brand) => {
          return brand.is.delivery_supported && !brand.timeslots.delivery_is_user_defined;
        }).length >= 2 &&
        this.siteDeliveryDestinations.length >= 1
      );
    },
    isPickupSupported() {
      return (
        this.locationDetails?.brands &&
        this.locationDetails.brands.filter((brand) => {
          return brand.is.pickup_supported;
        }).length >= 2
      );
    },
  },
  methods: {
    ...mapActions('sites', [
      'getLocationGroup',
      'patchLocationGroup',
      'postLocation',
      'getLocation',
      'patchLocation',
      'getConfigPrivate',
      'getConfigPublic',
      'postConfigPrivate',
      'postConfigPublic',
      'getDeliveryDestinations',
    ]),
    closeDialog() {
      this.dialog = false;
    },
    handleMarketplaceStatusChange() {
      this.savingLoading = true;
      this.deleteMarketplaceConfirmed = !this.deleteMarketplaceConfirmed;
      this.loading = true;
      this.backup.market_place = this.market_place;
      this.save();
    },
    onFeeChange(type, value) {
      this[type] = { ...this[type], value: parseFloat(value, 10) || 0 };
    },
    label(type) {
      if (this[type].value === null) {
        return '$ Flat Fee';
      }
      return 'Flat Fee';
    },
    prefix(type) {
      if (this[type].value === null) {
        return '';
      }
      return '$';
    },
    validateForm() {
      return this.$refs.form.validate() && this.name && this.costcenter;
    },
    cancel() {
      this.$refs.form.resetValidation();
      this.goToSite(this.$route.params.site_id);
    },
    shouldUpdateConfig() {
      // this is a decision to see if there are any changes to the config
      return (
        this.backup.kds_configuration.business_unit_id !==
          this.kds_configuration.business_unit_id ||
        this.backup.kds_configuration.has_expediter_kds !== this.kds_configuration.has_expediter_kds
      );
    },
    showModal() {
      this.MarketplaceModal.dialog = true;
    },
    goToSite(id) {
      this.$router.push({
        name: 'site-info',
        params: {
          site_id: id,
        },
      });
      if (this.deleteMarketplaceConfirmed) {
        // refresh dashboard to refresh location data (for tag)
        this.$router.go();
      }
    },
    defaultDeliveryDestinations() {
      const delivery_destination_intersect = this.locationDetails.brands.reduce(
        (intersect, brand, index) => {
          if (index === 0) {
            return brand.publicConfig.delivery_destinations || intersect;
          }
          return intersect.filter((element) =>
            brand.publicConfig.delivery_destinations.includes(element),
          );
        },
        [],
      );
      return delivery_destination_intersect;
    },
    defaultDeliveryDetail(detail) {
      if (this.locationDetails.brands.length === 0) return false;
      return this.locationDetails.brands.every((brand) => brand.publicConfig[detail]);
    },
    async addConfigsToChildBrands() {
      const brands = await Promise.all(
        this.locationDetails.brands.map(async (brand) => {
          const publicConfig = await this.getConfigPublic({ id: brand.id });
          return {
            ...brand,
            publicConfig,
          };
        }),
      );
      this.$set(this.locationDetails, 'brands', brands);
    },
    async save() {
      this.loading = true;
      const error = false;
      if (!this.validateForm() && !this.deleteMarketplaceConfirmed) {
        this.$toast('The form is not yet complete, please fix before saving');
        return;
      }
      if (this.isKDSModified) {
        await this.saveKDSInfo(!this.isModified);
      }
      if (this.isModified || this.deleteMarketplaceConfirmed) {
        // have to finish this before saving to upload to S3 and emit url to location properly
        await this.$refs.logoImage.save();
        await this.patchLocation({
          id: this.locationDetails.id,
          label: {
            en: this.name,
          },
          name: this.name,
          meta: {
            unit: this.costcenter,
            unit_id: this.costcenter,
          },
          market_place: this.deleteMarketplaceConfirmed
            ? null
            : {
                ...this.market_place,
                service_fee: this.market_place.service_fee
                  ? this.market_place.service_fee
                  : this.fee_base,
              },
        });

        const location = await this.getLocation({ id: this.locationDetails.id, nocache: true });
        location.brands.forEach((brand) => {
          this.getConfigPublic({ id: brand.id, nocache: true });
        });

        if (!error) {
          await this.$store.dispatch('sites/fetchSite', {
            id: this.$route.params.site_id,
            multigroupId: this.$route.params.app,
            fetchWithPermissions: true,
            nocache: 1,
          });
          this.loading = false;
          this.backup.name = this.name;
          this.backup.costcenter = this.costcenter;
          this.backup.market_place = this.market_place;
          this.$emit('update:location', true);
          if (this.deleteMarketplaceConfirmed) {
            await this.removeExpediter();
            this.$toast('The marketplace has been deleted successfully');
          } else {
            this.$toast('Saved successfully');
          }
          this.goToSite(this.$route.params.site_id);
        } else {
          console.error(error);
          this.$toast.error(`Unable to save information.`);
        }
      }
    },
    async removeExpediter() {
      if (this.privateConfig.kds_configurations) {
        this.privateConfig.kds_configurations = this.privateConfig.kds_configurations.filter(
          (config) => config.business_unit_id !== this.costcenter,
        );
        await this.postConfigPrivate({
          id: this.$route.params.site_id,
          config: this.privateConfig,
        });
        if (this.locationConfig.kdsConfigurations) {
          await this.postConfigPrivate({
            id: this.location_id,
            config: this.locationConfig,
          });
        }
      }
    },
    async saveKDSInfo(showToast) {
      try {
        if (this.shouldUpdateConfig()) {
          if (this.privateConfig.kds_configurations && this.locationConfig.kds_configurations) {
            // remove current configuration from the list
            this.privateConfig.kds_configurations = this.privateConfig.kds_configurations.filter(
              (config) => config.business_unit_id !== this.costcenter,
            );
            this.privateConfig.kds_configurations.push(this.kds_configuration);
          } else {
            this.kds_configuration.business_unit_id = this.costcenter;
            this.privateConfig.kds_configurations = [this.kds_configuration];
          }
          this.locationConfig.kds_configurations = [this.kds_configuration];
          // insert the the new config into the list
          if (
            this.kds_configuration.business_unit_id &&
            this.kds_configuration.business_unit_id !== ''
          ) {
            await this.postConfigPrivate({
              id: this.$route.params.site_id,
              config: this.privateConfig,
            });
            await this.postConfigPrivate({
              id: this.location_id,
              config: this.locationConfig,
            });
            this.backup.kds_configuration = this.kds_configuration;
            if (showToast) {
              this.$emit('update:location', true);
              this.$toast('Saved successfully');
              this.goToSite(this.$route.params.site_id);
            }
          }
        }
      } catch (err) {
        console.error(err);
        this.$toast.error(`Unable to save KDS information.`);
      }
    },
    handleUpdateDropoffLocations({ locations }) {
      this.$set(this.market_place, 'delivery_destinations', locations);
    },
  },
  async mounted() {
    this.$store.dispatch('adminPanel/setLoading', true);
    try {
      const location = JSON.parse(localStorage.location);
      this.location_id = location.id;
      const isNewMarketplace = !location.market_place;
      this.$set(this, 'locationDetails', cloneDeep(location));
      if (location.id) {
        await this.addConfigsToChildBrands();
        const calls = await Promise.all([
          this.getLocationGroup({
            id: this.$route.params.site_id,
            nocache: true,
          }).catch(() => this.getLocationGroup({ id: this.$route.params.site_id })),
          this.getConfigPrivate({ id: this.$route.params.site_id }).catch((err) => {
            if (err && err.response && err.response.data.code !== 404) {
              console.error(err);
            }
            return {};
          }),
          this.getConfigPrivate({ id: this.location_id }).catch((err) => {
            if (err && err.response && err.response.data.code !== 404) {
              console.error(err);
            }
            return {};
          }),
          this.getDeliveryDestinations({
            id: this.$route.params.site_id,
          }),
        ]);
        const site = calls[0];
        const privateConfig = calls[1];
        const locationConfigResp = calls[2];
        const siteDeliveryDestinations = calls[3];
        const kdsConfigurations = locationConfigResp.kds_configurations || [];
        this.privateConfig = cloneDeep(privateConfig);
        this.locationConfig = cloneDeep(locationConfigResp);
        this.siteDeliveryDestinations = cloneDeep(siteDeliveryDestinations);
        this.name = location.name;
        this.costcenter = (location.meta && location.meta.unit) || '';
        const current_config = kdsConfigurations.find(
          (kdsConfiguration) => kdsConfiguration.business_unit_id === this.costcenter,
        );
        this.kds_configuration = current_config || this.kds_configuration;
        this.kds_configuration.business_unit_id = this.costcenter;
        this.$set(this, 'siteDetails', cloneDeep(site));
        this.$set(
          this,
          'market_place',
          cloneDeep(location.market_place) || {
            label: {
              en: '',
              fr: '',
            },
            location_description: {
              en: '',
              fr: '',
            },
            logo: '',
            is: {
              pickup_supported: this.isPickupSupported,
              delivery_supported: this.isDeliverySupported,
            },
            pickup_instruction: { en: '', fr: '' },
            service_fee: {
              type: 'dollar',
              value: 0,
            },
            delivery_fee: {
              type: 'dollar',
              value: 0,
            },
            delivery_destinations: this.defaultDeliveryDestinations(),
          },
        );

        const isServiceFeeEmpty =
          this.market_place &&
          this.market_place.service_fee &&
          this.market_place.service_fee.value === 0;

        const isDeliveryFeeEmpty =
          this.market_place &&
          this.market_place.delivery_fee &&
          this.market_place.delivery_fee.value === 0;
        // keep track of if original load had this value populated, used for checking if the field was modified
        this.serviceFeeHadValue =
          (this.market_place &&
            this.market_place.service_fee &&
            this.market_place.service_fee.value > 0) ||
          isNewMarketplace;
        if (isServiceFeeEmpty) {
          delete this.market_place.service_fee;
        }
        if (isDeliveryFeeEmpty) {
          delete this.market_place.delivery_fee;
        }
        this.backup.name = this.name;
        this.backup.kds_configuration = cloneDeep(this.kds_configuration);
        this.backup.costcenter = this.costcenter;
        this.$set(this.backup, 'market_place', cloneDeep(this.market_place));
        this.undefinedCostCenter = this.costcenter === '';

        if (location.market_place && !location.market_place.delivery_destinations) {
          this.$set(this.market_place, 'delivery_destinations', []);
        }

        if (this.market_place.is.delivery_supported && !this.market_place.delivery_details) {
          this.$set(this.market_place, 'delivery_details', {
            show_instructions: this.defaultDeliveryDetail('show_instructions'),
            show_single_timeslot: this.defaultDeliveryDetail('show_single_timeslot'),
            runner_app_enabled: this.defaultDeliveryDetail('runner_app_enabled'),
          });
        }
      } else {
        this.$refs.form.reset();
      }
      this.$store.commit('adminPanel/setViewTitle', {
        depth: 2,
        title: isNewMarketplace ? 'Add a New Marketplace' : 'Configure Marketplace',
        to: {
          name: 'marketplace-settings',
          params: {
            site_id: this.$route.params.site_id,
          },
        },
      });
    } catch (err) {
      console.error('could not fetch data', err);
    }
    this.$store.dispatch('adminPanel/setLoading', false);
  },
};
</script>

<style>
.toggle_margin {
  margin-left: 12px;
}
.help_icon {
  color: #000000;
  opacity: 100%;
}
.pickup_message {
  width: 70%;
  margin: 0 auto;
}
</style>
