import { VFlex } from 'vuetify/lib/components/VGrid';
import { VLayout } from 'vuetify/lib/components/VGrid';

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VLayout,
    { attrs: { column: "", "ml-3": "" } },
    [
      _c(VFlex, { attrs: { xs8: "" } }, [
        _c(
          "p",
          {
            staticClass:
              "Subtitle-1-Black-Medium-Emphasis-Left font-weight-medium"
          },
          [_vm._v("\n      Display timeslots as one time?\n    ")]
        ),
        _c(
          "div",
          { staticClass: "toggle_margin" },
          [
            _c("toggle", {
              staticClass: "toggle",
              attrs: { value: _vm.show_single_timeslot, position: "start" },
              on: {
                input: function($event) {
                  return _vm.$emit("update:show_single_timeslot", $event)
                }
              }
            })
          ],
          1
        )
      ]),
      _c(VFlex, { attrs: { xs8: "" } }, [
        _c(
          "p",
          {
            staticClass:
              "Subtitle-1-Black-Medium-Emphasis-Left font-weight-medium"
          },
          [_vm._v("\n      Allow delivery instruction?\n    ")]
        ),
        _c(
          "div",
          { staticClass: "toggle_margin" },
          [
            _c("toggle", {
              staticClass: "toggle",
              attrs: { value: _vm.show_instructions, position: "start" },
              on: {
                input: function($event) {
                  return _vm.$emit("update:show_instructions", $event)
                }
              }
            })
          ],
          1
        )
      ]),
      _c(VFlex, { attrs: { xs8: "" } }, [
        _c(
          "p",
          {
            staticClass:
              "Subtitle-1-Black-Medium-Emphasis-Left font-weight-medium"
          },
          [_vm._v("\n      Enable Bolter delivery app?\n    ")]
        ),
        _c(
          "div",
          { staticClass: "toggle_margin" },
          [
            _c("toggle", {
              staticClass: "toggle",
              attrs: { value: _vm.runner_app_enabled, position: "start" },
              on: {
                input: function($event) {
                  return _vm.$emit("update:runner_app_enabled", $event)
                }
              }
            })
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }